







import { Component, Vue } from "vue-property-decorator";
import { examplePersonas, Person } from "@/types/Person";
import axios from "axios";

@Component
export default class SavePeople extends Vue {
  msg = "";

  saveFile(): void {
    const users: Person[] = examplePersonas;
    axios
      .put(
        "https://id41bdeo1j.execute-api.eu-west-1.amazonaws.com/latest/users",
        users
      )
      .then(() => {
        this.msg = "Data saved.";
      });
  }
}
