









































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Person } from "@/types/Person";
import axios from "axios";

@Component
export default class SendMessage extends Vue {
  @Prop() contact: Person | undefined;
  sendingMessage = false;
  sentMessage = false;
  message = "";
  from = "";
  error = "";

  closeModal(): void {
    this.sentMessage = false;
    this.sendingMessage = false;
    this.error = "";
    this.message = "";
    this.from = "";
    this.$emit("close-modal");
  }

  sendMessage(): void {
    if (this.sendingMessage) {
      return;
    }
    this.sendingMessage = true;

    axios
      .post(
        "https://id41bdeo1j.execute-api.eu-west-1.amazonaws.com/latest/send",
        {
          from: this.from,
          to: this.contact,
          message: this.message,
        }
      )
      .then((res) => {
        if (res.status === 201) {
          this.sentMessage = true;
          this.sendingMessage = false;
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        this.error = `No se pudo enviar el mensaje a ${this.contact?.name}`;
        this.sendingMessage = false;
        this.sentMessage = false;
      });
  }
}
