

































































































import { Component, Vue } from "vue-property-decorator";
import SpainMap from "@/components/SpainMap.vue";
import axios from "axios";
import { Person } from "@/types/Person";
import { banderas, Comunidad, COMUNIDADES } from "@/types/Comunidad";
import SavePeople from "@/components/SavePeople.vue";
import SendMessage from "@/components/SendMessage.vue";
import { fixNamesCapitalization, SECURE_MODE } from "@/helpers/people";

@Component({
  components: { SendMessage, SavePeople, SpainMap },
})
export default class MapContainer extends Vue {
  comunidadesObj = Comunidad;
  autonomousCommunity: Comunidad | "" = "";
  people: Person[] = [];
  selectedPersonId = "";
  error = "";
  busqueda = "";
  label = "";
  banderas = banderas;

  get selectedPerson(): Person | undefined {
    return this.people.find((p) => p.id === this.selectedPersonId) || undefined;
  }

  created(): void {
    if (SECURE_MODE) {
      this.people = [];
      COMUNIDADES.forEach((comunidad) => {
        axios
          .get<Person[]>(
            "https://id41bdeo1j.execute-api.eu-west-1.amazonaws.com/latest/users-list/comunidad/" +
              encodeURI(comunidad)
          )
          .then((value) => {
            value.data.forEach((person) => {
              this.people.push(fixNamesCapitalization(person));
            });
          })
          .catch((reason) => {
            this.error = reason;
          });
      });
    } else {
      axios
        .get<Person[]>(
          "https://id41bdeo1j.execute-api.eu-west-1.amazonaws.com/latest/users-list"
        )
        .then((value) => {
          this.people = value.data.map((person) =>
            fixNamesCapitalization(person)
          );
        })
        .catch((reason) => {
          this.error = reason;
        });
    }
  }

  selectUser(id: string): void {
    this.selectedPersonId = id;
  }

  unselectUser(): void {
    this.selectedPersonId = "";
  }

  static normalize(str: string): string {
    const mapObj: Record<string, string> = {
      Á: "a",
      É: "e",
      Í: "i",
      Ó: "o",
      Ú: "u",
      Ñ: "n",
      Ü: "u",
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      ñ: "n",
      ü: "u",
    };
    const re = new RegExp(Object.keys(mapObj).join("|"), "gi");

    return str
      .replace(re, function (matched) {
        return mapObj[matched];
      })
      .toLowerCase();
  }

  peopleForComunidad(): Person[] {
    return this.people
      .filter(
        (p) =>
          p.comunidad === this.autonomousCommunity ||
          (this.autonomousCommunity === Comunidad.world &&
            !Object.values(Comunidad).includes(p.comunidad))
      )
      .filter(
        (p) =>
          MapContainer.normalize(`${p.name} ${p.surname} ${p.name}`).indexOf(
            MapContainer.normalize(this.busqueda)
          ) !== -1
      )
      .sort((persona1, persona2) => {
        return (
          persona1.surname.localeCompare(persona2.surname) ||
          persona1.name.localeCompare(persona2.name)
        );
      });
  }

  peopleForWorld(): Record<string, Person[]> {
    return this.peopleForComunidad()
      .sort(
        (persona1, persona2) =>
          persona1.comunidad.localeCompare(persona2.comunidad) ||
          persona1.surname.localeCompare(persona2.surname) ||
          persona1.name.localeCompare(persona2.name)
      )
      .reduce((country, person) => {
        if (!Object.keys(country).includes(person.comunidad)) {
          country[person.comunidad] = [];
        }
        country[person.comunidad].push(person);
        return country;
      }, {} as Record<string, Person[]>);
  }

  comunidadSelected(autonomousCommunity: Comunidad): void {
    if (this.autonomousCommunity === autonomousCommunity) {
      this.comunidadUnselected();
    } else {
      this.autonomousCommunity = autonomousCommunity;
    }
  }

  comunidadUnselected(): void {
    this.autonomousCommunity = "";
    this.busqueda = "";
  }
}
