




import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import * as d3 from "d3";
import { ExtendedFeature } from "d3";
import * as topojson from "topojson-client";
import * as topojsonSimplify from "topojson-simplify";
import { FeatureCollection, Geometry, GeometryObject } from "geojson";
import {
  CommunityProperties,
  Comunidad,
  earthPathD,
  SpainComunidad,
  SupportedMaps,
} from "@/types/Comunidad";

const COMPRESSION_THRESHOLD = 0.005;
const ASPECT_RATIO = 1 / 2;
const SELECTED_ZOOM = 2.5;
const SCALE = 2;
const CEUTA_MELILLA_WIDTH = 0.003;

// const MAP_CONF = {
//   file: "maps/countries/spain/spain-comunidad.json",
//   fileCanarias: "maps/countries/spain/spain-comunidad.json",
//   objects: "ESP_adm1",
//   nameProp: "NAME_1",
//   zoom: 2.5,
// };

@Component
export default class SpainMap extends Vue {
  @Prop() comunidadSelected: Comunidad | undefined;
  @Watch("comunidadSelected")
  onComunidadSelectedChange(): void {
    this.repaint();
  }
  private width = 1024;
  private height = 512;

  private maps: Record<
    SupportedMaps,
    {
      file: string;
      path: d3.GeoPath;
    }
  > = {
    peninsula: {
      file: "maps/countries/spain/spain-comunidad.json",
      path: d3.geoPath(),
    },
    canarias: {
      file: "maps/countries/spain/canary-islands-comunidad.json",
      path: d3.geoPath(),
    },
  };

  frame:
    | d3.Selection<SVGGElement, unknown, HTMLElement | null, undefined>
    | undefined;

  resize(): void {
    // const centered = this.autonomousCommunityGraph;
    this.calculateSize();
    this.loadMap();
    // this.autonomousCommunityGraph = centered;
  }

  created(): void {
    window.addEventListener("resize", this.resize);
  }

  destroyed(): void {
    window.removeEventListener("resize", this.resize);
  }

  init(): void {
    this.calculateSize();
    this.createFrame();
    this.loadMap();
  }

  calculateSize(): void {
    this.width = (this.$refs.map as HTMLElement).offsetWidth;
    this.height = this.width * ASPECT_RATIO;
    const projectionPeninsula: d3.GeoProjection = d3
      .geoMercator()
      .center([-3.6827461557, 39.2])
      .scale(this.width * SCALE)
      .translate([this.width / 2, this.height / 2]);
    const projectionCanarias = d3
      .geoMercator()
      .center([-8, 32.7])
      .scale(this.width * SCALE)
      .translate([this.width / 2, this.height / 2]);

    this.maps.peninsula.path = d3.geoPath().projection(projectionPeninsula);
    this.maps.canarias.path = d3.geoPath().projection(projectionCanarias);
  }

  createFrame(): void {
    const svg = d3.select(this.$refs.map as HTMLElement).append("svg");
    this.frame = svg.append("g");
  }

  loadMap(): void {
    if (this.frame) {
      this.frame.selectAll("*").remove();
      this.frame
        .append("rect")
        .attr("class", "background")
        .attr("width", this.width)
        .attr("height", this.height)
        .on("click", this.clickedMap);

      this.frame
        .append("text")
        .attr("x", (50 * this.width) / 1100)
        .attr("y", (200 * this.width) / 1100)
        .text("Mapa de congresistas.")
        .attr("font-family", "Arial")
        .attr("font-size", `${(20 * this.width) / 1100}px`)
        .attr("fill", "white");
      this.frame
        .append("text")
        .attr("x", (50 * this.width) / 1100)
        .attr("y", (220 * this.width) / 1100)
        .text("¡contacta con cualquier compañero!")
        .attr("font-family", "Arial")
        .attr("font-size", `${(20 * this.width) / 1100}px`)
        .attr("fill", "white");

      Object.keys(this.maps).forEach((key) => {
        const map = this.maps[key as SupportedMaps];
        d3.json(map.file)
          .then(
            (esJsonMap): SpainComunidad => {
              const preSimplified = topojsonSimplify.presimplify(
                esJsonMap as SpainComunidad
              );
              return topojsonSimplify.simplify(
                preSimplified,
                COMPRESSION_THRESHOLD
              );
            }
          )
          .then((mapEs: SpainComunidad) => {
            if (this.frame) {
              const geometryCollection = {
                ...mapEs.objects.ESP_adm1,
                geometries: mapEs.objects.ESP_adm1.geometries.map(
                  (geometry) => ({
                    ...geometry,
                    properties: { ...geometry.properties, mapName: key },
                  })
                ),
              };

              this.frame
                .append("g")
                .attr("id", "states")
                .selectAll("path")
                .data(
                  (topojson.feature(
                    mapEs,
                    geometryCollection
                  ) as FeatureCollection<Geometry, CommunityProperties>)
                    .features
                )
                .enter()
                .append("path")
                .attr("d", map.path)
                .on("click", this.clickedComunidad)
                .on(
                  "mouseenter",
                  (
                    ac: ExtendedFeature<GeometryObject, CommunityProperties>
                  ) => {
                    this.$emit("mapenter", ac.properties.NAME_1);
                  }
                )
                .on(
                  "mouseleave",
                  (
                    ac: ExtendedFeature<GeometryObject, CommunityProperties>
                  ) => {
                    this.$emit("mapleave", ac.properties.NAME_1);
                  }
                );

              this.frame
                .append("path")
                .datum(
                  topojson.mesh(mapEs, geometryCollection, function (a, b) {
                    return a !== b;
                  })
                )
                .attr("id", "state-borders")
                .attr("d", map.path);
            }
          });
      });
      this.frame
        .append("path")
        .attr("id", "world")
        .attr("d", earthPathD)
        .attr(
          "transform",
          `translate(${this.width * 0.74}, ${this.height * 0.77}) scale(${
            this.width * 0.00015
          })`
        )
        .on("click", this.clickedWorld)
        .on("mouseenter", () => {
          this.$emit("mapenter", Comunidad.world);
        })
        .on("mouseleave", () => {
          this.$emit("mapleave", Comunidad.world);
        });

      const ceutaYMelilla = this.frame
        .append("g")
        .attr("id", "ceutaYMelilla")
        .on("click", this.clickedCeutaYMelilla);

      ceutaYMelilla
        .append("circle")
        .attr("id", "ceutaHover")
        .classed("hover", true)
        .attr("cx", this.width * 0.442)
        .attr("cy", this.height * 0.793)
        .attr("r", this.width * CEUTA_MELILLA_WIDTH * 5);
      ceutaYMelilla
        .append("circle")
        .attr("id", "ceutaInner")
        .classed("inner", true)
        .attr("cx", this.width * 0.442)
        .attr("cy", this.height * 0.793)
        .attr("r", this.width * CEUTA_MELILLA_WIDTH);
      ceutaYMelilla
        .append("circle")
        .attr("id", "ceutaOuter")
        .classed("outer", true)
        .attr("cx", this.width * 0.442)
        .attr("cy", this.height * 0.793)
        .attr("r", this.width * CEUTA_MELILLA_WIDTH)
        .attr("stroke-width", this.width * 0.0015);

      ceutaYMelilla
        .append("circle")
        .attr("id", "melillaHover")
        .classed("hover", true)
        .attr("cx", this.width * 0.5255)
        .attr("cy", this.height * 0.844)
        .attr("r", this.width * CEUTA_MELILLA_WIDTH * 5);
      ceutaYMelilla
        .append("circle")
        .attr("id", "melillaInner")
        .classed("inner", true)
        .attr("cx", this.width * 0.5255)
        .attr("cy", this.height * 0.844)
        .attr("r", this.width * CEUTA_MELILLA_WIDTH);
      ceutaYMelilla
        .append("circle")
        .attr("id", "melillaOuter")
        .classed("outer", true)
        .attr("cx", this.width * 0.5255)
        .attr("cy", this.height * 0.844)
        .attr("r", this.width * CEUTA_MELILLA_WIDTH)
        .attr("stroke-width", this.width * 0.0015);

      ceutaYMelilla.on("mouseenter", () => {
        this.$emit("mapenter", Comunidad.ceutaYMelilla);
        ceutaYMelilla
          .selectAll(".outer")
          .transition()
          .duration(200)
          .attr("r", this.width * CEUTA_MELILLA_WIDTH * 2);
        ceutaYMelilla
          .selectAll(".inner")
          .transition()
          .duration(200)
          .attr("r", this.width * CEUTA_MELILLA_WIDTH * 0.9);
      });
      ceutaYMelilla.on("mouseleave", () => {
        this.$emit("mapleave", Comunidad.ceutaYMelilla);
        ceutaYMelilla
          .selectAll(".outer")
          .transition()
          .duration(200)
          .attr("r", this.width * CEUTA_MELILLA_WIDTH);
        ceutaYMelilla
          .selectAll(".inner")
          .transition()
          .duration(200)
          .attr("r", this.width * CEUTA_MELILLA_WIDTH);
      });
    }
  }

  mounted(): void {
    this.init();
  }

  clickedMap(): void {
    this.$emit("autonomousCommunity-unselected");
  }

  repaint(): void {
    let x: number, y: number, k: number;

    if (this.comunidadSelected && this.frame) {
      if (this.comunidadSelected === Comunidad.world) {
        x = this.width * 0.74 + this.width * 0.02677;
        y = this.height * 0.77 + this.width * 0.02677;
        k = SELECTED_ZOOM;
        this.animateAutonomousCommunitySelection(k, x, y);
      } else if (this.comunidadSelected === Comunidad.ceutaYMelilla) {
        x = this.width * 0.483;
        y = this.height * 0.819;
        k = SELECTED_ZOOM;
        this.animateAutonomousCommunitySelection(k, x, y);
      } else {
        this.frame.selectAll("path").filter((d) => {
          const selectedCommunity = d as ExtendedFeature<
            GeometryObject,
            CommunityProperties
          >;
          if (
            selectedCommunity?.properties?.NAME_1 === this.comunidadSelected
          ) {
            const centroid = this.maps[
              selectedCommunity.properties.mapName as SupportedMaps
            ].path.centroid(selectedCommunity);
            x = centroid[0];
            y = centroid[1];
            k = SELECTED_ZOOM;
            this.animateAutonomousCommunitySelection(k, x, y);
            return true;
          }
          return false;
        });
      }
    } else {
      x = this.width / 2;
      y = this.height / 2;
      k = 1;
      this.animateAutonomousCommunitySelection(k, x, y);
    }
  }

  clickedComunidad(
    ac: ExtendedFeature<GeometryObject, CommunityProperties>
  ): void {
    this.$emit("autonomousCommunity-selected", ac.properties.NAME_1);
  }

  clickedWorld(): void {
    this.$emit("autonomousCommunity-selected", Comunidad.world);
  }

  clickedCeutaYMelilla(): void {
    this.$emit("autonomousCommunity-selected", Comunidad.ceutaYMelilla);
  }

  private animateAutonomousCommunitySelection(
    scale: number,
    x: number,
    y: number
  ) {
    if (!this.frame) {
      return;
    }

    this.frame.selectAll("path").classed("active", (communityGraph) => {
      return (
        (communityGraph as ExtendedFeature<GeometryObject, CommunityProperties>)
          ?.properties?.NAME_1 === this.comunidadSelected
      );
    });

    this.frame
      .selectAll("#world")
      .classed("active", () => this.comunidadSelected === Comunidad.world);

    this.frame
      .selectAll("#ceutaYMelilla")
      .classed(
        "active",
        () => this.comunidadSelected === Comunidad.ceutaYMelilla
      );

    const initialTranslation = this.comunidadSelected
      ? this.width * 0.7
      : this.width * 0.5;

    this.frame
      .transition()
      .duration(750)
      .attr(
        "transform",
        `translate(${initialTranslation}, ${
          this.height / 2
        })scale(${scale})translate(${-x},${-y})`
      )
      .style("stroke-width", 1.5 / scale + "px");
  }
}
