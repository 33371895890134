






// @ is an alias to /src
import MapContainer from "@/components/MapContainer.vue";

export default {
  name: "Home",
  components: {
    MapContainer,
  },
  methods: {},
};
