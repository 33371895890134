import { Person } from "@/types/Person";

/**
 * If true, people will be uploaded and downloaded paginated. 1 call will be done per Comunidad wen saving and when loading.
 */
export const SECURE_MODE = true;

const lowWords = [
  "a",
  "ante",
  "bajo",
  "cabe",
  "con",
  "contra",
  "de",
  "desde",
  "hacia",
  "hasta",
  "para",
  "por",
  "sin",
  "so",
  "sobre",
  "tras",
  "el",
  "la",
  "los",
  "las",
  "un",
  "uno",
  "una",
  "unos",
  "unas",
  "del",
];
function fixNameCapitalization(str: string): string {
  const words = str.trim().split(" ");
  return words
    .map((word) => word.toLocaleLowerCase("es-ES"))
    .map((word) => {
      if (lowWords.includes(word)) {
        return word;
      } else {
        return word.charAt(0).toLocaleUpperCase("es-ES") + word.slice(1);
      }
    })
    .join(" ")
    .replace(/-(\w)/g, (str) => str.toLocaleUpperCase("es-ES"));
}
export function fixNamesCapitalization(p: Person): Person {
  return {
    ...p,
    name: fixNameCapitalization(p.name),
    surname: fixNameCapitalization(p.surname),
  };
}
